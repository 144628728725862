import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import FormComponent from "../components/ui/FormComponent";
import PostWithImgComponent from "../components/ui/PostWithImgComponent";
import WebsitesCardComponent from "../components/ui/WebsitesCardComponent";
import { TitleComponent } from "../components/ui/TitleComponent";
import { LinkUnderlineComponent } from "../components/ui/LinkUnderlineComponent";
import MarginComponent from "../components/ui/MarginComponent";
import { CenterComponent } from "../components/ui/CenterComponent";
import { CardPriceComponent } from "../components/ui/CardPriceComponent";

export default function IndexPage({ data, location }) {
  return (
    <Layout
      location={location.pathname}
      title="Разработка сайтов в Ташкенте - Jahondev"
      description="Заказывайте сайт у настоящих профессионалов своего дела. Разработка корпоративного сайта, создание лендинга, разработка интернет-магазина. Разработка сайтов любой сложности"
    >
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Разработка сайтов</TitleComponent>
        <WebsitesCardComponent />
      </MarginComponent>

      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Цена на разработку сайта</TitleComponent>
      </MarginComponent>
      <MarginComponent small="sm:mt-5" margin="mt-4">
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2">
          {data.websiteprice.nodes.map((item) => (
            <CardPriceComponent price={item} show />
          ))}
        </div>
        <CenterComponent>
          <LinkUnderlineComponent href="/cena-na-razrabotku-sayta">
            Весь прайс-лист
          </LinkUnderlineComponent>
        </CenterComponent>
      </MarginComponent>
      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Заказать сайт</TitleComponent>
        <FormComponent />
      </MarginComponent>

      <MarginComponent small="sm:mt-8" margin="mt-10">
        <TitleComponent>Наши недавние проекты</TitleComponent>
        <ul
          role="list"
          className="mt-2 mb-2 grid grid-cols-1 gap-5 sm:grid-cols-2"
        >
          {data.projects.nodes.map((project) => (
            <li key={project.id} className="bg-white shadow sm:rounded-lg pt-10 px-10">
              <PostWithImgComponent data={project} />
            </li>
          ))}
        </ul>
      </MarginComponent>

      <CenterComponent>
        <LinkUnderlineComponent tag="btn" href="/nashi-proekty">
          Все проекты
        </LinkUnderlineComponent>
      </CenterComponent>
    </Layout>
  );
}

export const query = graphql`
  query {
    projects: allSanityProjects(
      limit: 4
      sort: { fields: _createdAt, order: ASC }
    ) {
      nodes {
        name
        description
        id
        slug {
          current
        }
        image {
          asset {
            id
            gatsbyImageData(width: 700)
          }
        }
      }
    }
    auditprice: allSanityPrice(
      filter: { cat: { elemMatch: { name: { eq: "audit" } } } }
      limit: 2
      sort: { fields: _createdAt, order: DESC }
    ) {
      nodes {
        cost
        days
        name
        slug {
          current
        }
      }
    }
    websiteprice: allSanityPrice(
      filter: { cat: { elemMatch: { name: { eq: "development" } } } }
      limit: 2
      sort: { fields: _createdAt, order: ASC }
    ) {
      nodes {
        cost
        days
        name
        slug {
          current
        }
      }
    }
  }
`;
